import React from 'react';
import { Link } from 'react-router-dom';
import { Background, Container, Content } from './styled';
import logo from '../../assets/MARCA_FUNDACAO_MT_versao_vertical_colorida.png';
import { DateUtils } from '../../util/Date.Utils';

const NotFound = () => {
  return (
    <Container>
      <Content>
        <img src={logo} alt="Fundação MT" />
        <h1>404</h1>
        <h3>Página não encontrada.</h3>
        <Link to="/">Ir para homepage</Link>
      </Content>
      <Background>
        <p>
          © {DateUtils.getYearFull()} Fundação MT - Todos os direitos estão
          reservados.
        </p>
      </Background>
    </Container>
  );
};

export default NotFound;
