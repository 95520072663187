import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Toast from '../../components/Toast';
import {
  Background,
  Container,
  Content,
  SignIn,
  Form,
  ErrorContainer
} from './styled';
import authService from '../../services/auth';
import { DateUtils } from '../../util/Date.Utils';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const signInValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail é inválido')
      .required('E-mail é obrigatório')
  });

  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(signInValidationSchema)
  });

  const onSubmit = async (input) => {
    try {
      const {
        data: { success, message }
      } = await authService.forgotPassword(input);

      if (!success) {
        toast(<Toast title="Atenção" content={message} />, { type: 'error' });
        return;
      }

      toast(
        <Toast
          title="Recuperação enviada com sucesso!"
          content="Verifique sua caixa de entrada para redefinir sua senha."
        />,
        {
          type: 'success'
        }
      );
      navigate('/');
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível enviar o email de recuperação de senha."
          content="Verifique se o email está correto."
        />,
        {
          type: 'error'
        }
      );
    }
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <span>Recuperar senha</span>
          <p>Para recuperar sua senha, digite o e-mail no campo abaixo:</p>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            ref={register}
            error={!!errors.email?.message}
          />
          <ErrorContainer>{errors.email?.message}</ErrorContainer>

          <Button text="Recuperar senha" disabled={isSubmitting} />
          <SignIn>
            <p>Lembrou a senha?</p>
            <Link to="/">Faça o login</Link>
          </SignIn>
        </Form>
      </Content>
      <Background>
        <p>
          © {DateUtils.getYearFull()} Fundação MT - Todos os direitos estão
          reservados.
        </p>
      </Background>
    </Container>
  );
};

export default ForgotPassword;
