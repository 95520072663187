/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdFilterList, MdSearch } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';

const Register = () => {
  const { selectedFarmId, farmName } = useMap();
  const { token, user, getNotifications } = useAuth();
  const {
    selectedManagement,
    setSelectedManagement,
    setDashboardState,
    dashboardState,
    updateScreen,
    setUpdateScreen
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [routineList, setRoutineList] = useState([]);
  const [totalPagesRoutine, setTotalPagesRoutine] = useState();
  const [searchField, setSearchField] = useState('');

  const getRoutineList = async (pageNumber = 1) => {
    setLoading(true);

    const params = new URLSearchParams([
      ['farm_id', selectedFarmId],
      ['page', pageNumber],
      ['filter', searchField || '']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setTotalPagesRoutine(response.data.data.pagination.totalPages);

      setRoutineList(response.data.data.routines);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleChangePageNextRoutine = (event, value) => {
    getRoutineList(value);
  };

  useEffect(() => {
    getRoutineList();
  }, []);

  useEffect(() => {
    if (searchField === '') {
      getRoutineList();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch size={28} onClick={() => getRoutineList()} />
          <S.SearchInput
            type="text"
            placeholder="Pesquisa por pragas, talhões, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <S.SubTitle>Fazenda {farmName}</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>Atividade</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data Inicial</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data Final</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Tipo</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routineList.map((row) => (
                <TableRow>
                  <TableCell align="center" style={{ borderLeft: 'none' }}>{row.title}</TableCell>
                  <TableCell align="center">
                    {formatDate(row.init_date)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(row.end_date)}
                  </TableCell>
                  <TableCell align="center">
                    {row.routine_type_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.encompass_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.status_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesRoutine}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageNextRoutine}
        />
      </Stack>
    </S.Container>

  );
};

export default Register;
