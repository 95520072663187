/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { TextField, Checkbox, useMediaQuery } from '@mui/material';
import Toast from '../../../../../../../components/Toast';
import { TypeEnum, UserFormProps } from './utils/userForm.types';
import useUserForm from './hooks/useUserForm';
import * as s from './utils/userForm.style';
import { useModal } from '../../../../../../../hooks/Modal';
import { useAuth } from '../../../../../../../hooks/Auth';
import TableCreate from '../../../../../../../components/TableCreate/TableCreate';
import { ResearchField } from '../../../../../../../components/ResearchField';
import { useUserRequestList } from './hooks/useUserRequestList';
import { phoneMask } from '../../../../../../../util';

const UserForm: FC<UserFormProps> = (props) => {
  const { modalCreatUserStyles } = useModal();
  const isMobile = useMediaQuery('(max-width: 560px)');

  const navigate = useNavigate();
  const {
    form,
    mensageSuccess,
    statusCode,
    verific: { VerificarCpf, VerificarEmail, verificarOrganizacao },
    userDate: {
      cpf_cnpj: oldCpf,
      email: oldEmail,
      nomeOrganizacao: organizacao
    }
  } = useUserForm(props);
  const { request, filter } = useUserRequestList();
  const { role } = useAuth();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const { type } = props;
  const isOwner = form.methods.watch('isFarmOwner');

  useEffect(() => {
    if (statusCode.codeStatus === 200) {
      toast(
        <Toast
          title={mensageSuccess[type].title}
          content={mensageSuccess[type].content}
        />,
        {
          type: 'success'
        }
      );
    }
    if (statusCode.codeStatus === 409) {
      toast(<Toast title={statusCode.message} content={statusCode.message} />, {
        type: 'warning'
      });
    }
  }, [statusCode]);

  const title: Record<string, string> = {
    new: 'Cadastro de usuário',
    edit: 'Editar  usuário',
    datails: 'Detalhes de usuário'
  };

  const modalContentDefault = {
    content: {
      width: '80vw',
      height: '70vh',
      inset: 0,
      transform: 'translate(11%, 10%)'
    }
  };

  const modalContentMobile = {
    content: {
      height: '100vh',
      inset: 0
    }
  };

  const styles = isMobile ? modalContentMobile : modalContentDefault;

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen
      style={{
        ...modalCreatUserStyles,
        ...styles
      }}
    >
      <>
        <s.title>{title[type]}</s.title>
        <s.container>
          <div className="column">
            <div className={isMobile ? 'column' : 'flex'}>
              <Controller
                name="cpf_cnpj"
                control={form.methods.control}
                render={({ onBlur, onChange, value }) => (
                  <TextField
                    style={{
                      backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                      flex: 1
                    }}
                    error={!form.cpfcnpjIsValid}
                    type="text"
                    id="cpf_cnpj"
                    onBlur={({ target }) => {
                      onBlur();

                      form.cpfcnpjOnBlur(target.value);
                      const { cpf_cnpj } = form.methods.getValues();
                      if (cpf_cnpj?.trim() !== oldCpf?.trim()) {
                        VerificarCpf.mutate(target.value);
                      }
                    }}
                    onChange={({ target }) => {
                      form.cpfcnpjOnChange(target.value);
                      onChange(target.value);
                    }}
                    value={value}
                    label="CPF ou CNPJ valido"
                    disabled={type === 'datails'}
                    variant="outlined"
                    autoFocus
                  />
                )}
              />
              <Controller
                name="phone_number"
                control={form.methods.control}
                render={({ onBlur, onChange, value }) => (
                  <TextField
                    type="text"
                    id="tel"
                    style={{
                      backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                      flex: 1
                    }}
                    onChange={({ target }) => {
                      const phone = phoneMask(target.value);
                      onChange(phone);
                    }}
                    value={value}
                    label="Telefone"
                    disabled={type === 'datails'}
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className={isMobile ? 'column' : 'flex'}>
              <Controller
                name="fullname"
                control={form.methods.control}
                render={(field) => (
                  <TextField
                    type="text"
                    {...field}
                    label="Nome"
                    disabled={type === 'datails'}
                    style={{
                      backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                      flex: 1
                    }}
                    variant="outlined"
                  />
                )}
              />
              <Controller
                name="email"
                control={form.methods.control}
                render={(field) => (
                  <TextField
                    onChange={field.onChange}
                    onBlur={({ target }) => {
                      field.onBlur(target.value);
                      const { email } = form.methods.getValues();
                      if (email?.trim() !== oldEmail?.trim()) {
                        VerificarEmail.mutate(target.value);
                      }
                    }}
                    value={field.value}
                    name={field.name}
                    type="text"
                    label="E-mail"
                    disabled={type === 'datails'}
                    style={{
                      backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                      flex: 1
                    }}
                    variant="outlined"
                  />
                )}
              />
            </div>
            {isOwner && (isMaster || isAdmin) && (
              <div className="flex">
                <Controller
                  name="nomeOrganizacao"
                  control={form.methods.control}
                  render={(field) => (
                    <TextField
                      onChange={field.onChange}
                      onBlur={({ target }) => {
                        field.onBlur(target.value);
                        const { nomeOrganizacao } = form.methods.getValues();
                        if (nomeOrganizacao?.trim() !== organizacao?.trim()) {
                          verificarOrganizacao.mutate(target.value);
                        }
                      }}
                      value={field.value}
                      name={field.name}
                      type="text"
                      label="Nome do Organização"
                      disabled={type === 'datails'}
                      style={{
                        backgroundColor: type === 'datails' ? '#DCDCDC' : '',
                        flex: 1
                      }}
                      variant="outlined"
                    />
                  )}
                />
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div>
                {!isOwner && type === 'new' && (isMaster || isAdmin) && (
                  <Controller
                    name="organizacao_id"
                    control={form.methods.control}
                    render={({ onChange, value }) => (
                      <div>
                        <FormControl sx={{ m: 0, width: '100%' }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            Organização
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={value}
                            label="Organização"
                            onChange={(data) => onChange(data.target.value)}
                          >
                            {request.itemsOrganizacao.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  />
                )}
                {(isMaster || isAdmin) && type === 'new' && (
                  <Controller
                    name="isFarmOwner"
                    control={form.methods.control}
                    render={({ value }) => (
                      <div
                        style={{
                          flex: 1,
                          textAlign: 'left'
                        }}
                      >
                        <label>
                          <Checkbox
                            checked={value}
                            onChange={form.onSelectFarmOwner}
                          />
                          Proprietário
                        </label>
                      </div>
                    )}
                  />
                )}
              </div>

              {type === TypeEnum.Edit && (
                <Controller
                  name="active"
                  control={form.methods.control}
                  render={({ value }) => (
                    <div
                      style={{
                        flex: 1,
                        textAlign: 'left'
                      }}
                    >
                      <label>
                        <Checkbox
                          checked={value}
                          onChange={form.onSelectActive}
                        />
                        Ativado
                      </label>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
          {type === TypeEnum.New && (
            <div
              style={{
                gap: 5,
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <PriorityHighIcon fontSize="small" htmlColor="#000" />
              <p style={{ color: '#000', fontSize: 15 }}>
                Senha Temporária Gerada "fmtid
                <span>{new Date().getFullYear()}</span>". Altere-a após o
                primeiro login.
              </p>
            </div>
          )}
          <s.containerbotton>
            <button
              type="button"
              className="containerbotton__bottoncancel"
              onClick={() => navigate(-1)}
            >
              Cancelar
            </button>

            <button
              type="button"
              className={`containerbotton__bottonregister${
                form.formValid ? '--disabled' : ''
              }`}
              onClick={form.submit}
              disabled={form.formValid}
            >
              {type === 'edit' ? 'Atualizar' : 'Registrar'}
            </button>
          </s.containerbotton>
        </s.container>
      </>
    </Modal>
  );
};

export default UserForm;
