/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { OrderedTable } from '../../../../../../../components/OrderedTable';
import Farm from '../../../../Farm';
import WebMap from '../../../../../../../components/WebMap';

const FarmList: FC = () => {
  return (
    <div style={{ display: 'flex', height: '100%', gap: 5 }}>
      <div style={{ flex: 1 }}>
        <Outlet />
      </div>
      <div style={{ flex: 4 }}>
        <WebMap />
      </div>
    </div>
  );
};

export default FarmList;
