import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import SignUpOtherData from './SignUpOtherData';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Toast from '../../components/Toast';
import api from '../../services/api';
import {
  Background,
  Container,
  Content,
  SignIn,
  Form,
  ErrorContainer
} from './styled';
import FlagEnviroment from '../../components/FlagEnviroment';
import { appConfig } from '../../config';
import authService from '../../services/auth';
import { DateUtils } from '../../util/Date.Utils';

const SignUp = () => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    setEmail('');
  }, []);
  const signInValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('E-mail é inválido.')
      .required('E-mail é obrigatório.')
  });

  const { handleSubmit, register, errors, setError } = useForm({
    resolver: yupResolver(signInValidationSchema)
  });

  const onSubmit = async (data) => {
    const checkedRegisteredEmail = await authService.checkForRegisteredEmail(
      data
    );
    if (checkedRegisteredEmail.statusResponse === false) {
      setEmail(data.email);
    } else {
      setEmail('');
      toast(<Toast title={`${checkedRegisteredEmail.response}.`} />, {
        type: 'error'
      });
      setError('email', {
        type: 'user-already-exists',
        message: checkedRegisteredEmail.response
      });
    }
  };
  return email === '' ? (
    <Container>
      {appConfig.enviroment === 'DEV' && <FlagEnviroment />}
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <span>Cadastre-se</span>
          <p>Para se cadastrar precisamos de algumas informações suas:</p>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            ref={register}
            error={!!errors.email?.message}
          />
          <ErrorContainer>{errors.email?.message}</ErrorContainer>

          <Button text="Cadastrar" />
          <SignIn>
            <p>Já tem cadastro?</p>
            <Link to="/">Faça o login</Link>
          </SignIn>
        </Form>
      </Content>
      <Background>
        <p>
          © {DateUtils.getYearFull()} Fundação MT - Todos os direitos estão
          reservados.
        </p>
      </Background>
    </Container>
  ) : (
    <SignUpOtherData userEmail={email} />
  );
};

export default SignUp;
