import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Toast from '../../components/Toast';

import logo from '../../assets/MARCA_FUNDACAO_MT_versao_vertical_colorida.png';

import {
  Background,
  Container,
  Content,
  Form,
  ErrorContainer,
  LogoContainer
} from './styled';
import authService from '../../services/auth';

export function ResetPassword() {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const navigate = useNavigate();

  const resetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .required('Senha obrigatória'),
    confirmed_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
      .required('Confirmação de senha obrigatória')
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register
  } = useForm({
    resolver: yupResolver(resetPasswordValidationSchema)
  });

  const onSubmit = async (input) => {
    try {
      const newData = {
        ...input,
        token
      };

      const {
        data: { success, message }
      } = await authService.resetPassword(newData);

      if (!success) {
        toast(<Toast title="Atenção" content={message} />, { type: 'error' });
        return;
      }

      toast(
        <Toast
          title="Senha alterada com sucesso!"
          content="Agora você pode fazer login com sua nova senha."
        />,
        { type: 'success' }
      );

      navigate('/');
    } catch (error) {
      toast(
        <Toast
          title="Erro ao alterar a senha"
          content="Verifique os dados e tente novamente."
        />,
        { type: 'error' }
      );
    }
  };

  return (
    <Container>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <LogoContainer>
            <img src={logo} alt="Fundação MT" />
          </LogoContainer>
          <h2>Redefinição de Senha</h2>
          <p>Digite a nova senha nos campos abaixo</p>

          <Input
            type="password"
            name="password"
            placeholder="Nova senha"
            ref={register}
            error={!!errors.password?.message}
          />
          <ErrorContainer>{errors.password?.message}</ErrorContainer>

          <Input
            type="password"
            name="confirmed_password"
            placeholder="Confirmar senha"
            ref={register}
            error={!!errors.password?.message}
          />
          <ErrorContainer>{errors.confirmed_password?.message}</ErrorContainer>

          <Button text="Enviar" type="submit" disabled={isSubmitting} />
        </Form>
      </Content>
      <Background>
        <p>© 2024 Apollo Softwares - Todos os direitos reservados.</p>
      </Background>
    </Container>
  );
}
