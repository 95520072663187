/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useEffect, useMemo, useState } from 'react';
import { TypeEnum, UserForm, UserFormProps } from '../utils/userForm.types';
import { useAuth } from '../../../../../../../../hooks/Auth';
import UserAPI from '../../../../../../../../APi/FarmApi/UserAPI';

const useUserForm = ({ type }: UserFormProps) => {
  const navigate = useNavigate();
  const { token, user, role } = useAuth();
  const [sistema, modulo, subModulo] = role.split('|');
  const isMaster: boolean = sistema.includes('MASTER');
  const isAdmin: boolean = sistema.includes('Admin');
  const userApi = useMemo(() => new UserAPI(token), []);
  const [cpfcnpjIsValid, setCpfcnpjIsValid] = useState<boolean>(true);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [userStatus, setUserStatus] = useState<{
    codeStatus: number;
    message?: string;
  }>({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const createUser = useMutation({
    mutationFn: async (data: UserForm) =>
      await userApi.UserRegister<UserForm>(data),
    onSuccess: async (data) => {
      setUserStatus({
        codeStatus: data.data.status,
        message: data.data.message
      });
      await queryClient.invalidateQueries(userApi.getUser);
      if (data.data.status === 200) navigate(-1);
    },
    onError: async (error, variables, context) => {
      setUserStatus({
        codeStatus: data.data.status,
        message: data.data.message
      });
    }
  });

  const updateUser = useMutation({
    mutationFn: async (data: UserForm) =>
      await userApi.UserUpdate<UserForm>(data),
    onSuccess: async (data) => {
      setUserStatus({
        codeStatus: data.data.status,
        message: data.data.message
      });
      await queryClient.invalidateQueries(userApi.getUser);
      if (data.data.status === 200) navigate(-1);
    },
    onError: async (error, variables, context) => {
      setUserStatus({
        codeStatus: data.data.status,
        message: data.data.message
      });
    }
  });

  function userMap(
    user_id: string,
    fullname: string,
    email: string,
    phone_number: string,
    cpf_cnpj: string,
    active: boolean
  ): UserForm {
    return {
      user_id,
      fullname,
      email,
      phone_number,
      cpf_cnpj,
      active
    };
  }

  const FormValidationSchema = Yup.object().shape({
    fullname: Yup.string().required(),
    email: Yup.string().email().required(),
    cpf_cnpj: Yup.string().required(),
    phone_number: Yup.string().required()
  });

  const methods = useForm<UserForm>({
    defaultValues: {
      user_id: '',
      fullname: '',
      cpf_cnpj: '',
      email: '',
      phone_number: '',
      active: true,
      password: '',
      isFarmOwner: false,
      nomeOrganizacao: '',
      organizacao_id: user.organizacao_id
    },
    resolver: yupResolver(FormValidationSchema)
  });
  const onSelectFarmOwner = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methods.setValue('isFarmOwner', checked);
  };
  const onSelectActive = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    methods.setValue('active', checked);
  };

  function mascaraTelefone(tel: string) {
    tel = tel.replace(/\D/g, '');
    tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
    tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
    tel = tel.substring(0, 15);
    return tel;
  }
  function ValidaCPF(cpfReturn: string) {
    return cpfReturn.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  function mascaraCnpj(cnjs: string) {
    return cnjs.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  const getUserSuccess = (data: UserForm) => {
    data.phone_number = mascaraTelefone(data.phone_number);
    data.cpf_cnpj =
      data.cpf_cnpj.length > 11
        ? mascaraCnpj(data.cpf_cnpj)
        : ValidaCPF(data.cpf_cnpj);
    methods.reset({
      ...methods.getValues(),
      ...data
    });
  };
  const userDate = useQuery({
    queryKey: [userApi.userDetails, { id }],
    queryFn: async () => userApi.UserDetalis(id.toString()),
    onSuccess: (data: any) => getUserSuccess(data.data.data)
  });
  const VerificarCpf = useMutation({
    mutationFn: async (cpf: string) =>
      await userApi.VerificarCpf(
        cpf.replace('.', '').replace('.', '').replace('-', '')
      ),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
    }
  });
  const verificarOrganizacao = useMutation({
    mutationFn: async (organizacao: string) =>
      await userApi.VerificarOrganizacao(organizacao),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
    }
  });
  const VerificarEmail = useMutation({
    mutationFn: async (email: string) => await userApi.VerificarEmail(email),
    onSuccess: async (data) => {
      if (data.data.status === 409) {
        setUserStatus({
          codeStatus: data.data.status,
          message: data.data.message
        });
      }
    }
  });
  useEffect(() => {
    const email = methods.control.getValues('email');
    const emailValid = /^[a-zA-Z0-9._-]+@\w+([-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    const owner =
      !!methods.control.getValues('nomeOrganizacao') ||
      !!methods.control.getValues('organizacao_id') ||
      type === 'edit' ||
      (!isAdmin && !isMaster);

    setFormValid(
      !methods.control.getValues('fullname') ||
        methods.control.getValues('cpf_cnpj').length <= 13 ||
        !email ||
        !emailValid ||
        methods.control.getValues('phone_number')?.length <= 14 ||
        !cpfcnpjIsValid ||
        !owner
    );
  }, [methods.control.watchInternal()]);

  const cpfcnpjOnChange = (pfcnpj: string) => {
    const strCPF = pfcnpj
      .replace('.', '')
      .replace('.', '')
      .replace('-', '')
      .replace('/', '');
    strCPF.length > 12
      ? $('#cpf_cnpj').mask('00.000.000/0000-00')
      : $('#cpf_cnpj').mask('000.000.000-00#');
  };
  const telOnChange = (tel: string) => {
    $('#tel').mask('(00) 00000-0000');
  };
  const cpfcnpjOnBlur = (pfcnpj: string) => {
    const strCPF = pfcnpj
      .replace('.', '')
      .replace('.', '')
      .replace('-', '')
      .replace('/', '');
    setCpfcnpjIsValid(
      strCPF.length < 12 ? cpf.isValid(pfcnpj) : cnpj.isValid(pfcnpj)
    );
  };

  const submit = methods.handleSubmit((data: UserForm) => {
    if (!cpfcnpjIsValid) return;

    data.cpf_cnpj = data.cpf_cnpj
      .replace('.', '')
      .replace('.', '')
      .replace('-', '')
      .replace('/', '');
    data.phone_number = data.phone_number
      .replace('-', '')
      .replace('(', '')
      .replace(')', '')
      .replace(' ', '');

    if (type === TypeEnum.New) {
      data.password = `fmtid${new Date().getFullYear()}`;
      if (!isAdmin && !isMaster) {
        data.organizacao_id = user.organizacao_id;
      }
      createUser.mutate(data);
    } else if (type === TypeEnum.Edit) {
      data.user_id = id;
      updateUser.mutate(data);
    }
  });

  const mensageSuccess: Record<string, { title: string; content: string }> = {
    new: {
      title: 'Usuário criado com sucesso',
      content: 'Usuário foi criado com sucesso'
    },
    edit: {
      title: 'Usuário atualizado com sucesso',
      content: 'Usuário foi atualizado com sucesso'
    }
  };
  return {
    form: {
      methods,
      submit,
      error: methods.errors,
      formValid,
      cpfcnpjOnChange,
      onSelectActive,
      telOnChange,
      cpfcnpjOnBlur,
      onSelectFarmOwner,
      cpfcnpjIsValid,
      isSuccess: createUser.isSuccess || updateUser.isSuccess
    },
    verific: {
      VerificarCpf,
      VerificarEmail,
      verificarOrganizacao
    },
    mensageSuccess,
    statusCode: userStatus,
    userDate: (userDate.data?.data?.data as UserForm) || ({} as UserForm)
  };
};

export default useUserForm;
