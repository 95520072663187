/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdDelete, MdSearch } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import Toast from '../../../../../../components/Toast';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';
import ViewTechnical from './components/ViewTechnical';

const Technical = () => {
  const { token, user, getNotifications } = useAuth();
  const {
    setOpendedModal,
    setItemSelectedVisible,
    setUpdateScreen,
    updateScreen,
    listTypeManager,
    selectedTechnician,
    setSelectedTechnician
  } = useContext(DashboardContext);
  const { openModal } = useModal();
  const { selectedFarmId } = useMap();

  const [loading, setLoading] = useState(false);
  const [technicianList, setTechnicianList] = useState([]);
  const [totalPagesTechnician, setTotalPagesTechnician] = useState();
  const [searchField, setSearchField] = useState('');

  const getTechnicianList = async (pageNumber = 1) => {
    setLoading(true);

    const paramsPage = new URLSearchParams([
      ['page', pageNumber],
      ['itensPerPage', 10],
      ['filter', searchField || '']
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Technician/List`,
        {
          params: paramsPage,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setUpdateScreen(false);

      const type_id = listTypeManager.filter((v) => v.name === 'Tarefa')[0].type_Id;

      const params = new URLSearchParams([['type_id', type_id]]);
      const responseTypeTask = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const addTaskName = [];

      response.data.data.technicians.forEach((v) => {
        const filterTask = responseTypeTask.data.data.values.filter((ft) => ft.value_Id === v.task);
        if (filterTask.length > 0) {
          const tech = {
            ...v,
            taskName: filterTask[0].name
          };
          addTaskName.push(tech);
        } else {
          addTaskName.push(v);
        }
      });

      setTotalPagesTechnician(response.data.data.pagination.totalPages);
      setTechnicianList(addTaskName);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const deleteTechnician = async (tech_id) => {
    setLoading(true);
    const params = new URLSearchParams([
      ['technician_id', tech_id],
      ['status', false]
    ]);
    try {
      const response = await api.delete(
        `${appConfig.apiHosts.prague}/Technician/Delete`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Técnico excluido com sucesso" />, {
        type: 'success'
      });

      getTechnicianList();
      setItemSelectedVisible('');
    } catch (error) {
      console.error(error);
      toast(<Toast title="Não foi possivel excluir o técnico" />, {
        type: 'error'
      });
      setLoading(false);
    }
  };

  const getTechnicianById = async (id) => {
    const params = new URLSearchParams([['technician_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Technician/ListByTechnicianId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedTechnician(response.data.data);
      setItemSelectedVisible('viewTechnical');
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePageTechnician = (event, value) => {
    getTechnicianList(value);
  };

  useEffect(() => {
    getTechnicianList();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getTechnicianList();
    }
  }, [updateScreen]);

  useEffect(() => {
    if (searchField === '') {
      getTechnicianList();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch size={28} onClick={() => getTechnicianList()} />
          <S.SearchInput
            type="text"
            placeholder="Pesquise por nome, telefone..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
        <S.AddBtnContainer>
          <Button
            type="button"
            text="Novo Técnico"
            className="addBtn"
            onClick={() => {
              setOpendedModal('modalNewTechnical');
              openModal();
            }}
          />
        </S.AddBtnContainer>
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <S.SubTitle>Executores registrados</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold', borderLeft: 'none' }}>Nome</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data de Cadastro</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Telefone</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {technicianList?.map((row) => (
                <TableRow>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getTechnicianById(row.technician_id);
                    }}
                    style={{ borderLeft: 'none', cursor: 'pointer' }}
                  >{row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getTechnicianById(row.technician_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {formatDate(row.created_at)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getTechnicianById(row.technician_id);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.phone}
                  </TableCell>
                  <TableCell align="center">
                    <button type="button" style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} onClick={() => deleteTechnician(row.technician_id)}>
                      <MdDelete style={{ color: '#f00' }} />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1} style={{ marginBottom: '10px' }}>
        <Pagination
          count={totalPagesTechnician}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageTechnician}
        />
      </Stack>
    </S.Container>
  );
};

export default Technical;
